.mat-form-field.mat-form-field-appearance-outline {

  /* Disabled */
  &.mat-form-field-disabled {
    opacity: 0.7 !important;
  }

  .mat-form-field-infix {
    padding: 10px 0;
  }

  .mat-form-field-wrapper {
    .mat-form-field-flex {
      min-height: 48px;
      padding: 0 16px;
    }
  }

  .mat-form-field-outline,
  .mat-form-field-outline-thick {

    .mat-form-field-outline-start,
    .mat-form-field-outline-end,
    .mat-form-field-outline-gap {
      border-width: 1px;
    }
  }

  .mat-form-field-subscript-wrapper {
    @apply mt-1;
  }

  &.mat-focused {
    .mat-form-field-wrapper .mat-form-field-flex {
      .mat-form-field-outline {
        color: rgba(var(--fuse-primary-rgb), var(--tw-text-opacity)) !important;
      }
    }
  }
}

.mat-form-field.mat-form-field-appearance-outline.fuse-mat-dense {
  .mat-form-field-infix {
    padding: 8px 0;
  }

  .mat-form-field-wrapper {
    .mat-form-field-flex {
      min-height: 40px;
    }
  }
}

.mat-form-field.mat-form-field-appearance-fill.no-margin-bottom .mat-form-field-wrapper {
  margin-bottom: 0 !important;
}

.mat-form-field.mat-form-field-appearance-fill.fuse-mat-dense.dense-text-area .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix textarea.mat-input-element {
  @apply px-0.5;
}

.month-year-picker-restricted .mat-calendar-period-button {
  pointer-events: none !important;
}

.month-year-picker-restricted .mat-calendar-arrow {
  display: none !important;
}

body.light .mat-calendar-body-disabled>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .light .mat-calendar-body-disabled>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  @apply text-disabled;
}

.mat-input-element[type='time']::after {
  content: '' !important;
}

.mat-form-field.mat-form-field-appearance-fill.no-wrapper-mb .mat-form-field-wrapper {
  margin-bottom: 0 !important;
}

.mat-form-field.mat-form-field-appearance-fill.disabled-input .mat-form-field-wrapper .mat-form-field-flex {
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.mat-option {
  font-size: 0.875rem !important;
}

.pe {
  pointer-events: auto;
}

label.mat-form-field-label:has(tb-info-tooltip) {
  padding-top: 3.5px;
}