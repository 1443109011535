p-tree:not(.no-ptree-border) {
  .p-tree {
    border-width: 1px;
    @apply rounded-2xl border-gray-300 shadow-sm;
  }
}

.p-tree {
  .p-tree-loading-icon {
    @apply text-primary;
    font-size: 250%;
    animation: rotate 1.2s infinite linear;
  }

  .p-tree-header {
    @apply bg-default p-4 rounded-t-2xl border-b-gray-300 border-b font-medium;
  }

  .p-tree-filter-container {
    @apply px-4 py-3 border-b-gray-300 border-b;

    .p-tree-filter {
      @apply pr-5;
    }

    .p-tree-filter-icon {
      @apply text-secondary right-4;
    }
  }

  .p-treenode-children {
    @apply ml-4;
  }

  .p-tree-container {
    @apply py-2;

    .p-treenode-content {
      @apply py-1;
    }

    .p-tree-node {
      @apply my-2;
    }

    .p-tree-toggler {
      @apply w-6;

      .p-tree-toggler-icon {
        @apply text-sm;
      }
    }
  }

  .p-tree-empty-message {
    @apply px-4 py-1;
  }
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
  @apply text-default;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate.p-highlight .p-checkbox-icon {
  @apply text-white;
}