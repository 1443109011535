:root {
  --content-padding: 1.25rem;
  --inline-spacing: 1rem;
}

.p-picklist-list-wrapper {
  border-width: 1px;
  @apply rounded-2xl border-gray-300 shadow-sm;
}

.p-picklist {
  .p-picklist-buttons {
    padding: 1.25rem;

    &.p-picklist-target-controls {
      @apply pr-0;
    }

    .p-button {
      margin-bottom: 0.5em;
      @apply bg-primary text-on-primary py-3 rounded-xl w-12;

      &:disabled {
        @apply bg-opacity-60 text-on-primary;
      }
    }
  }

  .p-picklist-header {
    @apply bg-default p-4 rounded-t-2xl border-b-gray-300 border-b font-medium;
  }

  .p-picklist-filter-container {
    @apply px-4 py-3 border-b-gray-300 border-b;

    .p-picklist-filter-input {
      @apply pr-5;
    }
    .p-picklist-filter-icon {
      @apply text-secondary right-0.5;
    }
  }

  .p-picklist-list {
    @apply py-1 rounded-b-2xl;

    .p-picklist-item {
      @apply px-4 py-1;

      &:focus {
        @apply border-primary border-opacity-20 border;
      }

      &:hover {
        @apply bg-default;
      }

      &.p-highlight {
        @apply bg-primary bg-opacity-10;
      }
    }
  }
}
