:root {
  
  --splash-screen-logo-dflt: url("../../assets/images/logo/Trailblazer_Logo_Icon_white-cropped.svg");
  --nav-header-logo-small-dflt: url("../../assets/images/logo/Trailblazer_Logo_Icon_gradient-cropped.svg");
  --nav-header-logo-large-dflt: url("../../assets/images/logo/Trailblazer_Logo_Horiz_no_tag_gradient-cropped.svg");
  --sign-in-logo-dflt: url("../../assets/images/logo/Trailblazer_Logo_Horiz_primary_gradient-cropped.svg");

  --splash-screen-logo: var(--splash-screen-logo-dflt);
  --nav-header-logo-small: var(--nav-header-logo-small-dflt);
  --nav-header-logo-large: var(--nav-header-logo-large-dflt);
  --sign-in-logo: var(--sign-in-logo-dflt);
}

img#splash-screen-logo {
  content: var(--splash-screen-logo);
}
img#nav-header-logo-small {
  content: var(--nav-header-logo-small);
}
img#nav-header-logo-large {
  content: var(--nav-header-logo-large);
}

img#sign-in-logo {
  content: var(--sign-in-logo);
}
