.mat-tab-body {
  animation: fade-out 0.35s;
  opacity: 0;
}
.mat-tab-body.mat-tab-body-active {
  animation: fade-in 0.35s;
  opacity: 1;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
