/* ----------------------------------------------------------------------------------------------------- */
/*  @ Main Tailwind file for injecting utilities.
/* ----------------------------------------------------------------------------------------------------- */
@tailwind utilities;

@tailwind components;

@layer components {
  .tb-card-panel {
    @apply rounded-2xl shadow bg-card;
  }

  .tb-detail-tab-loader {
    @apply h-[calc(100vh-30rem)];
  }
}
