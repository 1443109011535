/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import third party library styles here.
/* ----------------------------------------------------------------------------------------------------- */

/* Perfect scrollbar */
@import "perfect-scrollbar/css/perfect-scrollbar.css";

/* Quill */
@import "quill/dist/quill.core.css";
@import "quill/dist/quill.snow.css";

/* Ngx-Toastr */
@import "ngx-toastr/toastr";

@import "./vendor-themes/dragula/dragula.scss";

//PRIMENG
@import "./vendor-themes/primeng/paginator-trailblazer.scss";
@import "./vendor-themes/primeng/picklist-trailblazer.scss";
@import "./vendor-themes/primeng/tree-trailblazer.scss";
@import "./vendor-themes/primeng/orderlist-trailblazer.scss";
@import "./vendor-themes/primeng/datatable-trailblazer.scss";
@import "./vendor-themes/primeng/checkbox-trailblazer.scss";
/* @import "./vendor-themes/primeng/button-trailblazer.scss"; */
@import "./vendor-themes/primeng/treeselect-trailblazer.scss";
@import "./vendor-themes/primeng/contextmenu-trailblazer.scss";