.p-checkbox {
  width: 20px;
  height: 20px;
}
.p-checkbox .p-checkbox-box {
  @apply border-slate-300 bg-white text-slate-400;
  border: 2px solid;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  @apply text-white;
  transition-duration: 0.2s;
  font-size: 14px;
}
.p-checkbox .p-checkbox-box.p-highlight {
  @apply border-accent-500 bg-accent-500;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  @apply border-accent;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  @apply border-accent-500 shadow-accent-200;
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  @apply text-white border-accent-600 bg-accent-600;
}

p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
  @apply border-warn;
}

.p-input-filled .p-checkbox .p-checkbox-box {
  @apply bg-gray-50;
}
.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
  @apply bg-accent;
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  @apply bg-gray-50;
}
.p-input-filled
  .p-checkbox:not(.p-checkbox-disabled)
  .p-checkbox-box.p-highlight:hover {
  @apply bg-accent-900;
}

.p-checkbox-label {
  @apply ml-2;
}
