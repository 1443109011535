@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

button:not(.sw-is-loading) {
  .mat-button-wrapper {
    transition: padding 0.2s ease-in-out;
  }

  .sw-is-loading-spinner {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
}

button.sw-is-loading {
  >.sw-is-loading-spinner {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    position: absolute;
    animation: rotate 0.8s infinite linear;
    @apply border-2 border-primary;
    border-right-color: transparent;
    border-radius: 50%;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  &:not([mat-icon-button]):not([ng-reflect-sw-is-loading-spinner="false"]) {
    .mat-button-wrapper {
      transition: padding 0.2s ease-in-out;
      @apply pl-4;
    }

    .sw-is-loading-spinner {
      @apply left-2.5;
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  &[mat-icon-button] {
    .sw-is-loading-spinner {
      height: 85%;
      width: 85%;
    }

    &[color="warn"] {
      .sw-is-loading-spinner {
        opacity: 1;
        transition: opacity 0.2s ease-in-out;
        position: absolute;
        animation: rotate 0.8s infinite linear;
        @apply border-2 border-warn;
        border-right-color: transparent;
        border-radius: 50%;
      }
    }
  }
}

:not(button).sw-is-loading {
  &::after {
    content: ""; // ::before and ::after both require content
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @apply bg-white;
    opacity: 0.7;
    z-index: 100;
  }

  >.sw-is-loading-spinner {
    opacity: 1;
    z-index: 101;
    transition: opacity 0.2s ease-in-out;
    animation: rotate 0.8s infinite linear;
    @apply border-4 border-primary-400;
    border-right-color: transparent;
    border-radius: 50%;

    margin: auto;
    width: 3em;
    height: 3em;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &[swDisableAbsolutePosition] {
    >.sw-is-loading-spinner {
      position: unset;
    }
  }

  &[swSm] {
    >.sw-is-loading-spinner {
      border-width: 3px;
      width: 2em;
      height: 2em;
    }
  }

  &[swXSm] {
    >.sw-is-loading-spinner {
      border-width: 2px;
      width: 1em;
      height: 1em;
    }
  }

  &[swMd] {
    >.sw-is-loading-spinner {
      border-width: 6px;
      width: 6em;
      height: 6em;
    }
  }

  &[swLg] {
    >.sw-is-loading-spinner {
      @apply border-8;
      width: 10em;
      height: 10em;
    }
  }

  &[swLeft] {
    >.sw-is-loading-spinner {
      margin-left: unset;
      left: 1em;
    }
  }

  &[swRight] {
    >.sw-is-loading-spinner {
      margin-right: unset;
      right: 1em;
    }
  }

  &[swClear]::after {
    opacity: 0;
  }
}

.mat-select.sw-is-loading,
.mat-input.sw-is-loading {
  &[disabled] {
    cursor: not-allowed;
  }
}