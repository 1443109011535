.p-contextmenu {
    padding: 0.25rem 0;
    border: 0 none;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    width: 12.5rem;
    @apply bg-card text-default;
}

.p-contextmenu .p-menuitem-link {
    padding: 0.75rem 1.25rem;
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
    @apply text-default;
}

.p-contextmenu .p-menuitem-link .p-menuitem-text {
    @apply text-default;
}

.p-contextmenu .p-menuitem-link .p-menuitem-icon {
    @apply text-default;
    margin-right: 0.5rem;
}

.p-contextmenu .p-menuitem-link .p-submenu-icon {
    @apply text-default;
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
    @apply bg-primary-100;
}

// .p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
//     color: #495057;
// }

// .p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
//     color: #6c757d;
// }

// .p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
//     color: #6c757d;
// }

.p-contextmenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem #BFDBFE;
}

.p-contextmenu .p-submenu-list {
    padding: 0.25rem 0;
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 6px;
}

.p-contextmenu .p-menuitem {
    margin: 0;
}

.p-contextmenu .p-menuitem:last-child {
    margin: 0;
}

.p-contextmenu .p-menuitem.p-menuitem-active>.p-menuitem-link {
    @apply bg-primary-100;
}

.p-contextmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text {
    @apply text-primary;
}

.p-contextmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-icon,
.p-contextmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-submenu-icon {
    @apply text-primary;
}

.p-contextmenu .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.25rem 0;
}

.p-contextmenu .p-submenu-icon {
    font-size: 0.875rem;
}

.p-contextmenu .p-menuitem-badge {
    background: #3B82F6;
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 6px;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}