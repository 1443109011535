.p-treeselect {
    display: inline-flex;
    cursor: pointer;
    position: relative;
    user-select: none;
}

.p-treeselect-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.p-treeselect-label-container {
    overflow: hidden;
    flex: 1 1 auto;
    cursor: pointer;
}

.p-treeselect-label  {
    display: block;
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
}

.p-treeselect-label-empty {
    overflow: hidden;
    visibility: hidden;
}

.p-treeselect-token {
    cursor: default;
    display: inline-flex;
    align-items: center;
    flex: 0 0 auto;
}

.p-treeselect-items-wrapper {
    overflow: auto;
}

.p-treeselect-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.p-treeselect-filter-container {
    position: relative;
    flex: 1 1 auto;
}

.p-treeselect-filter-icon {
    position: absolute;
    top: 50%;
    @apply -m-2;
}

.p-treeselect-filter-container .p-inputtext {
    width: 100%;
}

.p-treeselect-close {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    overflow: hidden;
    position: relative;
    margin-left: auto;
}

.p-treeselect-clear-icon {
    position: absolute;
    top: 50%;
    @apply -m-2;
}

.p-fluid .p-treeselect {
    display: flex;
}

.p-treeselect-clear-icon {
    position: absolute;
    top: 50%;
    @apply -m-2;
    cursor: pointer;
}

.p-treeselect-clearable {
    position: relative;
} 

.p-treeselect {
  @apply bg-white border-solid border-gray-300;
  border-width: 1px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 3px;//no 3px variable
}
.p-treeselect:not(.p-disabled):hover {
  @apply border-primary-900;
}
.p-treeselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem;
  @apply shadow-blue-300 border-primary-900;
}
.p-treeselect .p-treeselect-label {
  @apply p-2;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-treeselect .p-treeselect-label.p-placeholder {
  @apply text-zinc-500;
}
.p-treeselect.p-treeselect-chip .p-treeselect-token {
  @apply py-1 px-2 mr-2 bg-gray-200 text-zinc-600 rounded-2xl;
}
.p-treeselect .p-treeselect-trigger {
  background: transparent;
  @apply text-zinc-500;
  width: 2.357rem;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;//no 3px variable
}

p-treeselect.ng-invalid.ng-dirty > .p-treeselect {
  @apply border-red-500;
}

.p-inputwrapper-filled .p-treeselect.p-treeselect-chip .p-treeselect-label {
  @apply py-1 px-2;
}

.p-treeselect-panel {
  @apply bg-white text-zinc-600;
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-treeselect-panel .p-treeselect-header {
  @apply py-2 px-4 border-b-0 text-zinc-600 m-0 bg-gray-50;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container {
  @apply mr-2;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter {
  @apply pr-6;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter-icon {
  @apply right-2 text-zinc-500;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter {
  padding-right: 3rem;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter-clear-icon {
  @apply right-8;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-close {
  @apply w-8 h-8 text-zinc-500 border-0;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-close:enabled:hover {
  @apply text-zinc-600 bg-gray-100;
  border-color: transparent;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem;
  @apply shadow-blue-300;
}
.p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
  border: 0 none;
}
.p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
  @apply py-2 px-4 text-zinc-600;
  background: transparent;
}

.p-input-filled .p-treeselect {
  @apply bg-gray-50;
}
.p-input-filled .p-treeselect:not(.p-disabled):hover {
  @apply bg-gray-50;
}
.p-input-filled .p-treeselect:not(.p-disabled).p-focus {
  @apply bg-white;
}

p-treeselect.p-treeselect-clearable .p-treeselect-label-container {
  @apply pr-6;
}
p-treeselect.p-treeselect-clearable .p-treeselect-clear-icon {
  right: 2.357rem;
}