.p-orderlist-controls {
  @apply ml-1;
}

.p-orderlist-list-container {
  border-width: 1px;
  @apply rounded-2xl border-gray-300 shadow-sm;

  .p-orderlist-header {
    @apply bg-default p-4 rounded-t-2xl border-b-gray-300 border-b font-medium;
  }

  .p-orderlist-list {
    @apply overflow-auto py-1;

    .p-orderlist-item {
      @apply px-4 py-1;

      &:hover {
        @apply bg-default;
      }

      &:not(.p-highlight):not(:hover) .selected-fields-button {
        @apply invisible;
      }

      &.p-highlight {
        @apply bg-accent bg-opacity-10;
      }
    }
  }
}
